import { Route, Routes } from "react-router-dom";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import './App.scss';

import Home from './pages/Home/Home';
import Error404 from "./pages/Error404/Error404";

function App() {
  return (
    <div className="App">
      <Header />

      <main>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="*" element={<Error404 />}/>
        </Routes>
      </main>

      <Footer />
    </div>
  );
}

export default App;
