import "video.js/dist/video-js.css";
import { useVideoJS } from "react-hook-videojs";
// import useWindowDimensions from '../../hooks/useWindowDimensions';

import './Section.scss';

function Section(props) {
  // const windowDimensions = useWindowDimensions();
  
  const { Video } = useVideoJS(
    { 
      autoplay: true,
      muted: true,
      loop: true,
      controls: false,
      poster: props.bgImgUrl,
      sources: [{ 
        src: props.bgVideoUrl || ""
      }],
      playsinline: true
    },
  );

  return(
    <section 
      id={props.id}
      className={"section" + 
        (props.className ? " " + props.className : "") +
        (props.mode ? " section--mode-" + props.mode : "")
      }
    >
      <div className="section__bg">
        {props.bgImgUrl &&
          <img 
            className="section__bgImage"
            src={props.bgImgUrl}
            alt="" 
          />
        }
        {props.bgVideoUrl && 
        // windowDimensions.width >= 768 &&
          <div className="section__bgVideo">
            <Video className="section__bgVideoPlayer" />
          </div>
        }
        
        {props.bgOverlay && 
          <div className="section__bgOverlay"  style={{ background: props.bgOverlay }}></div>
        }
      </div>
      {props.children}
    </section>
  );
}

export default Section;