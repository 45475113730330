import './Footer.scss';

function Footer() {
  return (
    // reference footer from https://agriculture.basf.us/
    <footer>
      <div className="container">
        <div className="social">
          <div>
            <strong className="social__title">Share With Us</strong>
            <ul className="social__list">
              <li>
                <a href="https://twitter.com/basfagproducts" target="_blank" rel="nofollow noreferrer"><img src="/assets/images/icon_twitter.png" alt="" width="38" height="38"/><span className="visually-hidden">Twitter</span></a>
              </li>

              <li>
                <a href="https://www.facebook.com/BASFAgriculturalSolutionsUSA/" target="_blank" rel="nofollow noreferrer"><img src="/assets/images/icon_facebook.png" alt="" width="38" height="38"/><span className="visually-hidden">FaceBook</span></a>
              </li>

              <li>
                <a href="https://www.instagram.com/basf_global/" target="_blank" rel="nofollow noreferrer"><img src="/assets/images/icon_instagram.png" alt="" width="38" height="38"/><span className="visually-hidden">Instagram</span></a>
              </li>
            </ul>
          </div>
        </div>

        <ul className="links">
          <li className="links__col">
            <ul>
              <li><a href="https://agriculture.basf.us/crop-protection/services/consultant-finder.html">Find a Rep</a></li>

              <li><a href="https://agriculture.basf.us/crop-protection/products.html">Product Finder</a></li>

              <li><a href="https://agriculture.basf.us/crop-protection/contact-us.html">Contact Us</a></li>

              <li><a href="https://www.basf.com/us/en/who-we-are.html">About Us</a></li>

              {/* <li><a href="https://agriculture.basf.com/us/en/legal/data-protection1.html">Data Policy</a></li> */}

              <li><a href="https://www.basf.com/global/en/careers.html">Careers</a></li>
            </ul>
          </li>

          <li className="links__col">
            <ul>
              <li><a href="https://www.basf.com/us/en/who-we-are/sustainability.html">Sustainability</a></li>

              <li><a href="https://www.basf.com/us/en/who-we-are/innovation.html">Innovation</a></li>

              <li><a href="https://www.basf.com/us/en/who-we-are/diversity.html">Diversity</a></li>

              <li><a href="https://www.basf.com/global/en/media.html">News &amp; Media</a></li>

              <li><a href="https://www.basf.com/en.html">BASF Global</a></li>

              <li><a href="https://www.basf.com/us/en/who-we-are/organization/executive-profiles.html">Executive Profiles</a></li>
            </ul>
          </li>

          <li className="links__col">
            <ul>
              <li><a href="https://www.basf.com/global/en/careers/why-join-basf.html">Why Join BASF</a></li>

              <li><a href="http://www.basf.com/global/en/careers/jobs/my-profiles.html">My Career</a></li>

              <li><a href="https://www.basf.com/global/en/media/news-releases.html">News Releases</a></li>

              <li><a href="https://www.basf.com/global/en/media/multimedia.html">Multimedia</a></li>

              <li><a href="https://agriculture.basf.us/crop-protection/news-events/media-statements.html">Media Statements</a></li>

              <li><a href="https://agriculture.basf.us/community-relations/corporate-initiatives.html">Corporate Initiatives</a></li>
            </ul>
          </li>

          <li className="links__col">
            <ul>
              <li><a href="https://agriculture.basf.us/#">We Create Chemistry </a></li>

              <li><a href="https://www.basf.com/us/en/who-we-are/strategy.html">Strategy &amp; Organization</a></li>

              <li><a href="https://www.basf.com/us/en/who-we-are/sustainability/whats-new.html">What’s New</a></li>

              <li><a href="https://www.basf.com/us/en/who-we-are/sustainability/management-goals-and-dialog.html">Management and Instruments</a></li>

              <li><a href="https://www.basf.com/global/en/who-we-are/innovation/how-we-innovate.html">Our Research</a></li>

              <li><a href="https://www.basf.com/us/en/who-we-are/innovation/our-innovations.html">Our Innovations</a></li>
            </ul>
          </li>

          <li className="links__col">
            <ul>
              <li><a href="https://www.basf.com/global/en/investors.html">Investor Relations</a></li>

              {/* <li><a href="https://www1.basf.com/we-create-chemistry/urban-living.en.html">Urban Living </a></li>

              <li><a href="https://www1.basf.com/we-create-chemistry/smart-energy.en.html">Smart Energy</a></li>

              <li><a href="https://www1.basf.com/we-create-chemistry/food.en.html">Food</a></li> */}
            </ul>
          </li>
        </ul>
      </div>

      <nav className="container notice">
        <ul>
          <li>
            <a href="https://www.basf.com/us/en/legal/ccpa-notice.html">Notice under California Consumer Protection Act (“CCPA”)</a>
          </li>
        </ul>
      </nav>
    </footer>
  )
}

export default Footer;