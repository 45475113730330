import './FeatureCard.scss';

function FeatureCard(props) {
  return(
    <div className={"feature-card" + (props.mobileExtend ? " feature-card--mobile-extend-" + props.mobileExtend : "" )}>
      <div className="feature-card__image">
        <img src={props.imageUrl} alt={props.imageAltText ? props.imageAltText : ""} />
      </div>
      <div className="feature-card__header">
        {props.header}
      </div>
      <div className="feature-card__body">
        {props.body}
      </div>
    </div>
  )
}

export default FeatureCard;