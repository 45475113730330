import './grid.scss';

function Container(props) {
  return(
    <div 
      className={"container" + (props.className ? " " + props.className : "")}
      id={props.id}
      style={props.maxWidth ? { maxWidth: props.maxWidth } : {}}
    >
      {props.children}
    </div>
  )
}

export default Container;