import Container from '../../components/grid/Container';
import Row from '../../components/grid/Row';
import Col from '../../components/grid/Col';
import Section from '../../components/Section/Section';
import Cta from '../../components/Cta/Cta';

import './Error404.scss';

function Error404(props) {
  return(
    <>
      <Section
        id="error404"
      >
        <Container>
          <Row>
            <Col>
              <h1>Error 404</h1>
              <p>The page you requested was not found.</p>
              <p><Cta mode="link" href="/" openInNewTab={false} content="Go to home page" /></p>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Error404;