import { useLocation } from 'react-router-dom';

import Col from '../grid/Col';
import Container from './../grid/Container';
import Row from '../grid/Row';

import './Header.scss';

function Header(props) {
  const location = useLocation();
  return (
    <header className="header">
      <Container className="header__container">
        <Row>
          <Col>
            {location.pathname === '/'
              ?
              <img className="header__logo" src="/assets/images/BASF_logo_v1_white.svg" alt="BASF" />
              :
              <a className="header__logoLink" href="/" aria-label="BASF home">
                <img className="header__logo" src="/assets/images/BASF_logo_v1_white.svg" alt="BASF" />
              </a>
            }

          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default Header;