import React, { useEffect, useState } from 'react';
import "video.js/dist/video-js.css";
import { useVideoJS } from "react-hook-videojs";
import useWindowDimensions from '../../hooks/useWindowDimensions';

import './VideoOverlay.scss';

function VideoOverlay(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const windowDimensions = useWindowDimensions();

  const { Video, player, ready } = useVideoJS(
    {
      controls: props.controls,
      sources: [{ src: props.src }],
      responsive: true,
      fluid: true,
      playsinline: true
    }
  )

  useEffect(() => {
    if (props.autoplay && ready && player && isModalOpen) {
      setTimeout(() => {
        player.currentTime(0);
        player.play();
      }, 100);
    }
  }, [ready, player, isModalOpen, props.autoplay]);

  useEffect(() => {
    // handle Escape key
    document.addEventListener('keydown', (event) => {
      if (event.key === "Escape") {
        setIsModalOpen(false);
      }
    }, false);
  }, []);

  return(
    <div className="video-overlay">
      <div className="video-overlay__link" onClick={() => setIsModalOpen(true)}>
        {props.link}
      </div>
      <div className={"video-overlay__modal" + 
        (isModalOpen ? " video-overlay__modal--show" : "")
      }>
        {isModalOpen &&
        <div className="video-overlay__modal-inner">
          <div className={"video-overlay__video" + 
            (props.autoplay ? " video-overlay__video--autoplay" : "")}
            style={windowDimensions.width > windowDimensions.height 
              ?  
              { width: (windowDimensions.height * 1.778) + 'px' }
              : 
              null}
          >
            <Video />
          </div>
          
          <button
            aria-label="Close video modal"
            className="video-overlay__modal-close"
            onClick={() => setIsModalOpen(false)}
            tabIndex={isModalOpen ? 0 : -1}
          ><span></span></button>
        </div>
        }
      </div>
    </div>
  )
}

export default VideoOverlay;