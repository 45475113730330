import { useState, useEffect } from 'react';

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    {
      width: window.innerWidth,
      height: window.innerHeight
    }
  );

  const handleWindowResize = () => {
    setWindowDimensions(
      {
        width: window.innerWidth,
        height: window.innerHeight
      }
    );
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return windowDimensions;
}

export default useWindowDimensions;