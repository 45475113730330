import './Home.scss';

import Hero from '../../components/Hero/Hero';
import Container from '../../components/grid/Container';
import Row from '../../components/grid/Row';
import Col from '../../components/grid/Col';
import VideoOverlay from '../../components/VideoOverlay/VideoOverlay';
import PlayIcon from '../../components/icons/PlayIcon';
import Section from '../../components/Section/Section';
import Cta from '../../components/Cta/Cta';
import FeatureCard from '../../components/FeatureCard/FeatureCard';
// import GetUpdatesForm from '../../components/GetUpdatesForm/GetUpdatesForm';
import WheelAnimation from '../../components/WheelAnimation/WheelAnimation';

function Home(props) {
  return (
    <>
      {/* Revytek/Veltyma */}

      <Hero
        className="hero--rv"
        // should only load the animated GIF for desktop
        bgImgUrl="/assets/images/Banner-Static.png"
        bgVideoUrl="/assets/videos/BASF_Microscopy_Trimmed_2.mp4"
        bgOverlay={"rgba(0, 0, 0, 0.48"}
        mode="dark"
      >
        <Container maxWidth={960}>
          <Row>
            <Col>
              <h1>Scouting Your Fields Isn&rsquo;t Enough</h1>
              <p>Disease is infecting your crops earlier than you think. Take a look and see<br className="desktop-only" /> how critical it is to get ahead of the situation before it's too late.</p>
              <VideoOverlay
                link={
                  <button className="hero__watch_video"
                    aria-label="Watch the video"
                  >Watch the Video
                    <PlayIcon />
                  </button>}
                src="/assets/videos/BASF_Microscopy_Subtitled.mp4"
                controls={true}
                autoplay={true}
              />
            </Col>
          </Row>
        </Container>
      </Hero>

      <Section
        mode="dark"
        bgImgUrl="/assets/images/WheelBackground.png"
        // bgOverlay="#00793A"
        id="defend-your-crops"
      >
        <WheelAnimation
          content={
            <>
              <h3>Defend Your Crops From Threats You Can't See Yet</h3>
            </>
          }
        />
      </Section>

      <Section
        mode="light"
        bgOverlay="#FFFFFF"
        id="learn-more"
      >
        <Container>
          <Row>
            <Col className="intro">
              <p>Trust your season to Veltyma<sup>®</sup> fungicide and Revytek<sup>®</sup> fungicide. Both contain Revysol<sup>®</sup>, the first and only Isopropanol-Azole. This unique molecular structure provides stronger binding, rainfast performance and excellent efficacy.</p>
              <p>Get broader, stronger, longer disease control for your crops before disaster hits.</p>
            </Col>
            <Col cols="6" colsMobile="12">
              <FeatureCard
                imageUrl="/assets/images/RevytekCTA.png"
                header={
                  <h3>Revytek fungicide</h3>
                }
                body={
                  <>
                    <p>Set your soybean crop up for success with the unparalleled performance of Revytek fungicide.</p>
                    <p><Cta mode="link" href="https://agriculture.basf.us/crop-protection/products/fungicides/revytek.html" openInNewTab={true} content="Learn More" /></p>
                  </>
                }
                mobileExtend="left"
              />
            </Col>
            <Col cols="6" colsMobile="12">
              <FeatureCard
                imageUrl="/assets/images/VeltymaCTA.png"
                header={
                  <h3>Veltyma fungicide</h3>
                }
                body={
                  <>
                    <p>Corn growers, look no further. Veltyma fungicide will help you make every bushel count.</p>
                    <p><Cta mode="link" href="https://agriculture.basf.us/crop-protection/products/fungicides/veltyma.html" openInNewTab={true} content="Learn More" /></p>
                  </>
                }
                mobileExtend="right"
              />
            </Col>
          </Row>
        </Container>
      </Section>

      <Section
        mode="dark"
        bgOverlay="#65AC1E"
        id="plant-health-tool"
      >
        <Container>
          <Row>
            <Col>
              <h2>Plant Health Alerts Tool</h2>
              <p>Take the path to higher yield potential. Get Plant Health alerts and updates on environmental stressors that can affect crops in your area.</p>
              <p>
                <Cta mode="button" href="https://planthealth.basf.us/planthealth.html" openInNewTab={true} content="Sign Up" />
              </p>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Home;