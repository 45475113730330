import './grid.scss';

function Row(props) {
  return(
    <div className={"row" + (props.className ? " " + props.className : "")} id={props.id}>
      {props.children}
    </div>
  )
}

export default Row;