import "./Cta.scss";

function Cta(props) {
  return (
    <a className={"cta" + (props.mode === "button" ? " cta--button" : " cta--link")}
      href={props.href}
      target={props.openInNewTab ? "_blank" : ""}
      rel={props.openInNewTab ? "noopener noreferrer" : ""}
      onClick={props.onClick}
    >
      {props.content}
    </a>
  )
}

export default Cta;