import Section from '../Section/Section';
import './Hero.scss';

function Hero(props) {
  return(
    <Section
        className={"hero" + (props.className ? " " + props.className : "")}
        bgImgUrl={props.bgImgUrl}
        bgVideoUrl={props.bgVideoUrl}
        bgOverlay={props.bgOverlay}
        mode={props.mode}
    >
      {props.children}
    </Section>
  )
}

export default Hero;