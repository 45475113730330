import { useEffect, useRef, useState } from 'react';

import './WheelAnimation.scss';

function WheelAnimation(props) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [rotation, setRotation] = useState(60);

  const containerElement = useRef(null);

  // listen for this component to come into view to trigger animation
  useEffect(() => {
    if (containerElement.current) {
      let inViewScrollThreshold = containerElement.current.getBoundingClientRect().top + 
      document.documentElement.scrollTop / 2 - window.innerHeight / 4;

      setIsAnimating(scrollPosition > inViewScrollThreshold);
    }
  }, [containerElement, scrollPosition]);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  }

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // animate the wheel as long as component is in view
  useEffect(() => {
    const animate = () => {
      setRotation(rotation => rotation - 60);
    }

    let interval;

    setTimeout(() => {
      interval = setInterval(() => {
        if (isAnimating) {
          animate();
        }
      }, 3500);
    }, 0);
    
    return () => clearInterval(interval);
  }, [isAnimating]);

  return(
    <div className="wheel-animation">
      <div className="wheel-animation__container" ref={containerElement}>
        <div className="wheel-animation__container-inner">
          <img 
            className="wheel-animation__wheel"
            style={{transform: "rotate(" + rotation + "deg)"}} 
            src="/assets/images/Wheel.png"
            alt="Wheel showing a corn crop at days 1, 5, 9, 12, 15 and 19" 
          />
          <img
            className="wheel-animation__overlay"
            src="/assets/images/WheelOverlay.png"
            alt="" 
          />
        </div>
      </div>
      <div className="wheel-animation__content">
        {props.content}
      </div>
    </div>
  )
}

export default WheelAnimation;