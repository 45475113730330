function PlayIcon(props) {
  return (
    <svg className={"icon icon--play " + props.className} style={props.style} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 8.5L6 11.9641L6 5.0359L12 8.5Z" fill="white" />
      <rect x="1" y="1.5" width="14" height="14" rx="7" stroke="white" strokeWidth="2" />
    </svg>

  )
}

export default PlayIcon;