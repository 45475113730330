import './grid.scss';

function Col(props) {
  return(
    <div 
      className={
        "col" + 
        (props.className ? " " + props.className : "") +
        (props.cols ? " col--" + props.cols : " col--12") +
        (props.colsMobile ? " col-mobile--" + props.colsMobile : "")
      }
      id={props.id}
    >
        {props.children}
    </div>
  )
}

export default Col;